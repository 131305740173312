/* Mobile-first design, starting with the smallest screens */

.social-media {
    background-color: var(--background-color);
    padding: 20px 0 20px 0;
    text-align: center;
}

.social-media-title {
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
}

.social-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.social-icon {
    font-size: 1.8rem;
    color: var(--navbar-bg-color); /* Neutral color for the default state */
    padding: 8px;
    border-radius: 50%;
    background-color: #eeecec; /* Darker off-white/light gray background for better contrast */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: color 0.5s ease, transform 0.5s ease; /* Color and scale transition */
    transition-delay: 0s; /* Color happens immediately */
    opacity: 0; /* Start hidden */
    transform: scale(0.8); /* Start smaller for center zoom effect */
}

.social-icon.in-view {
    opacity: 1; /* Fade in */
    transform: scale(1.1); /* Zoom slightly when in view */
    color: var(--brand-color); /* Brand-specific color transition */
    transition: opacity 0.5s ease, transform 0.5s ease, color 0.5s ease, box-shadow 0.7s ease; /* Stagger shadow */
    transition-delay: 0.5s, 0.8s, 1s; /* Shadow delayed by 0.5s after color */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); /* Shadow effect after color */
}


/* Apply brand colors when the icon is in view */
.social-icon.facebook.in-view {
    color: #1877F2; /* Facebook blue */
}

  

.social-icon.snapchat.in-view {
    color: #FFFC00; /* Snapchat yellow */
}

.social-icon.tiktok.in-view {
    color: #69C9D0; /* TikTok blue-green */
}

.social-icon.youtube.in-view {
    color: #FF0000; /* Youtube red */
}

.social-icon.pinterest.in-view {
    color: #E60023; /* Pinterest red */
}

.social-icon.threads.in-view {
    color: #000000; /* Threads black */
}

.social-icon.instagram.in-view {
    color: #E4405F; /* Threads black */
}

/* Disable shadow on other icons when one is hovered */
.disable-shadow {
    box-shadow: none !important;
    opacity: 0.6; /* Optionally reduce opacity to make the non-hovered icons less prominent */
}

/* Ensure that when an icon is hovered, it maintains its shadow */
.social-icon:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2) !important;
}

.underline {
    border: none;
    border-top: 2px solid #cccccc; /* A subtle light gray line */
    margin: 20px auto;
    width: 90%;
}

/* Mobile-first fancy footer design for 240px screen width */
.contact-info {
    background-color: #2c3e50; /* Dark background for a footer look */
    padding: 20px 10px; /* Smaller padding for mobile screens */
    color: #ffffff; /* White text color for contrast */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 3px solid #e7a61a; /* Gold border at the top */
    box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    text-align: center;
    gap: 8px; /* Gap between buttons */
    font-size: 0.9rem;
}

.contact-info a {
    color: #e7a61a; /* Gold color for links */
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px; /* Slight letter spacing */
    transition: all 0.3s ease-in-out;
    text-decoration: none; /* Remove underline */
}

.contact-info a:hover {
    color: #ffffff; /* White text on hover */
    transform: scale(1.05); /* Subtle scale on hover */
}

.contact-button {
    background-color: #444444; /* Dark gray background for buttons */
    padding: 8px 12px;
    border-radius: 8px;
    width: 100%;
    max-width: 220px;
    text-align: center;
    margin-bottom: 8px;
    height: 40px; /* Set a fixed height for the buttons */
    display: flex;
    align-items: center;
    justify-content: center;

    text-overflow: ellipsis; /* Add ellipsis if the text overflows */
    overflow: hidden; /* Hide overflowing text */
}

.contact-button:hover {
    background-color: #e7a61a; /* Gold on hover */
}

.footer-info {
    background-color: #2c3e50;
    padding: 10px;
    color: #ffffff;
    text-align: center;
    font-size: 0.8rem;
  }
  
  .footer-info .copyright,
  .footer-info .powered-by {
    margin: 5px 0;
  }


/* For screens 320px and above */
@media (min-width: 320px) {
    .social-icons {
        flex-direction: row;
        gap: 15px;
    }

    .social-icon {
        font-size: 1rem;
        padding: 10px;
    }
    .contact-info {
        padding: 30px 20px; /* Increase padding */
        gap: 10px; /* Increase space between items */
    }

    .contact-button {
        padding: 10px 15px; /* Larger padding for bigger screens */
        max-width: 240px; /* Slightly larger width */
    }

    .contact-info a {
        letter-spacing: 1.2px; /* Increase letter spacing for readability */
    }

    .footer-info {
        font-size: 0.9rem;
      }
}

/* For screens 768px and above */
@media (min-width: 768px) {
    .social-media-title {
        font-size: 2rem;
        margin-bottom: 30px;
    }

    .social-icons {
        gap: 20px;
    }

    .social-icon {
        font-size: 2.5rem;
        padding: 12px;
    }

    .underline {
        width: 60%;
    }

    .contact-info {
        flex-direction: row;
        justify-content: space-around; /* Space between buttons */
        padding: 40px 50px;
    }

    .contact-button {
        padding: 12px 20px;
        max-width: 260px; /* Larger button width */
    }

    .contact-info a {
        font-size: 1.1rem;
        letter-spacing: 1.5px; /* More elegant letter spacing */
    }

    .footer-info {
        font-size: 1rem;
        padding: 20px;
        display: flex; /* Flexbox layout for row alignment */
        justify-content: center;
        align-items: center;
      }
    
      .footer-info .copyright,
      .footer-info .powered-by {
        margin: 0 10px; /* Add space between the items */
      }
    
      .footer-info .divider {
        margin: 0 10px;
        border-left: 1px solid #ffffff; /* Divider between the items */
        height: 20px;
      }
}

/* For screens 1024px and above */
@media (min-width: 1024px) {
    .social-icons {
        gap: 30px;
    }

    .social-icon {
        font-size: 3rem;
        padding: 15px;
    }

    .underline {
        width: 50%;
    }

    .contact-info {
        padding: 50px 100px; /* More padding for larger screens */
        gap: 30px; /* More spacing between buttons */
    }

    .contact-button {
        font-size: 1.2rem; /* Increase button font size */
        padding: 15px 30px;
        max-width: 300px; /* Larger button width */
    }

    .contact-info a {
        font-size: 1.3rem; /* Larger font for larger screens */
    }

    .footer-info {
        font-size: 1.1rem;
      }
}
