/* Base CSS for smallest screens */
.about-section {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgb(29, 29, 29);
  overflow: hidden;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease, transform 1s ease;
}

.about-image {
  background-image: url('https://i.imgur.com/hyRUd35.jpeg');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 50vh; /* Adjust for small screens */
  position: relative;
}

.about-content {
  background-color: rgba(29, 29, 29, 0.9);
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;
}

.about-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--navbar-link-hover-color);
  padding-bottom: 5px;
}

.about-description {
  font-size: 1em;
  line-height: 1.4;
  margin-bottom: 10px;
  color: #ddd;
}

.about-button {
  background-color: var(--navbar-link-hover-color);
  border: none;
  color: white;
  font-size: 1.2em;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-button:hover {
  background-color: var(--navbar-bg-color);
}

.highlight {
  display: block;
  text-align: center;
}

.description-text {
  width: 90%;
  text-align: center;

}

.highlight, .description-text {
  opacity: 0; /* Ensure initial state for animations */
  transition: opacity 1s ease-in; /* Animate opacity */
}

.about-section.in-view {
  opacity: 1;
  transform: translateY(0);
}

.about-section.in-view .description-text,
.about-section.in-view .highlight {
  opacity: 1; /* Reveal text when in view */
}

/* Media query for screens 600px and up */
@media (min-width: 600px) {
  .about-title {
    font-size: 2.5em;
  }
  .about-description {
    font-size: 1.2em;
  }
  .about-button {
    font-size: 1.4em;
    margin-bottom: 20px;
  }
}



@media (min-width: 1200px) {
  /* Base Styles for mobile-first design */
  .about-section {
    flex-direction: row;
    position: relative;
    background-color: rgb(29, 29, 29); /* Dark background with slight transparency */
    overflow: hidden; /* Ensure smooth transitions */
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
  }

  .about-section.in-view {
    opacity: 1;
    transform: translateY(0);
  }

  .about-image {
    background-image: url('https://i.imgur.com/hyRUd35.jpeg');
    background-position: center;
    background-size: cover;
    width: 50%;
    height: 70vh; /* Full screen height */
    position: relative;
  }


  .about-content {
    background-color: rgba(29, 29, 29, 0.9); /* Dark background with slight transparency */
    color: #fff;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    right: 0; /* Start from the right */
    width: 0%; /* Start with no width */
    height: 100%; /* Full height to cover the image */
    z-index: 2;
    overflow: hidden;
    transition: width 2s ease; /* Animate width */
  }

  .about-section.in-view .about-content {
    width: 100%; /* Expand to full width */
  }

  .about-title {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 2px solid var(--navbar-link-hover-color);
    padding-bottom: 10px;
  }

  .about-description {
    font-size: 1.1em;
    line-height: 1.2;
    margin-bottom: 20px;
    color: #ddd;
  }

  .description-pair {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .highlight {
    font-weight: bold;
    color: #fff;
    display: block;
    font-size: 1.2em;
    margin-bottom: 10px;
    align-self: center;
    opacity: 0;
    text-align: left;
    transition: opacity 1s ease-in; /* Animate width */
    transition-delay: 1.5s; /* Delay the animation by 2 seconds (adjust based on previous animations) */
  }
  
  .description-text {
    width: 45%;
    opacity: 0;
    display: block;
    align-self: center;
    text-align: left;
    transition: opacity 1s ease-in; /* Animate width */
    transition-delay: 2s; /* Delay the animation by 2 seconds (adjust based on previous animations) */
  }

  .about-section.in-view .description-text {
    opacity: 1; /* Expand to full width */
  }

  .about-section.in-view .highlight {
    opacity: 1; /* Expand to full width */
  }


  .about-button {
    background-color: var(--navbar-link-hover-color);
    border: none;
    color: white;
    font-size: 1.2em;
    padding: 10px 20px;
    margin-top: 0px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
  }

  .about-button:hover {
    background-color: var(--navbar-bg-color);
  }
}

@media (min-width: 1550px) {
  /* Base Styles for mobile-first design */
  .about-section {
    flex-direction: row;
    position: relative;
    background-color: rgb(29, 29, 29); /* Dark background with slight transparency */
    overflow: hidden; /* Ensure smooth transitions */
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
  }

  .about-section.in-view {
    opacity: 1;
    transform: translateY(0);
  }

  .about-image {
    background-image: url('https://i.imgur.com/hyRUd35.jpeg');
    background-position: center;
    background-size: cover;
    width: 50%;
    height: 75vh; /* Full screen height */
    position: relative;
  }


  .about-content {
    background-color: rgba(29, 29, 29, 0.9); /* Dark background with slight transparency */
    color: #fff;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    right: 0; /* Start from the right */
    width: 0%; /* Start with no width */
    height: 100%; /* Full height to cover the image */
    z-index: 2;
    overflow: hidden;
    transition: width 2s ease; /* Animate width */
  }

  .about-section.in-view .about-content {
    width: 100%; /* Expand to full width */
  }

  .about-title {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 2px solid var(--navbar-link-hover-color);
    padding-bottom: 10px;
  }

  .about-description {
    font-size: 1.4em;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #ddd;
  }

  .description-pair {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .highlight {
    font-weight: bold;
    color: #fff;
    display: block;
    font-size: 1.2em;
    margin-bottom: 10px;
    align-self: center;
    text-align: left;
  }

  .description-text {
    width: 45%;
    opacity: 0;
    display: block;
    align-self: center;
    text-align: left;
    transition: opacity 1s ease-in; /* Animate width */
    transition-delay: 2s; /* Delay the animation by 2 seconds (adjust based on previous animations) */
  }

  .about-section.in-view .description-text {
    opacity: 1; /* Expand to full width */
  }

  .about-button {
    background-color: var(--navbar-link-hover-color);
    border: none;
    color: white;
    font-size: 1.5em;
    padding: 15px 30px;
    margin-top: 0px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
  }

  .about-button:hover {
    background-color: var(--navbar-bg-color);
  }
}
